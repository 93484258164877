export const skillsData = [
    'Ruby',
    'Rails',
    'Javascript',
    'React',
    'Node.js',
    'PostgreSQL',
    'MongoDB',
    'MySQL',
    'C#',
    'Java',
    'AWS',
    'GCP',
    'GraphQL',
    'Firebase',
    'Unity',
    'Git',
    'HTML',
    'Figma',
    'CSS',
    'Bootstrap',
    'Typescript',
    'Figma',
    'MaterialUI',
    'Tailwind',
    'Microsoft Office',
]