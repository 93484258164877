export const educationData = [
    {
        id: 1,
        institution: 'Universty of Western Ontario',
        course: 'Bachelor of Engineering Science - Software Engineering',
        startYear: '2019',
        endYear: '2023'
    },
    {
        id: 2,
        institution: 'Sir Wilfrild Laurier Secondary School',
        course: 'Ontario Secondary School Diploma',
        startYear: '2015',
        endYear: '2019'
    }
]